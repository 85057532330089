import React from "react";
import ReactPlayer from "react-player";

interface IVideoFProps {
  className?: string;
  url: string;
  style?: any;
  title: string;
  description?: string;
}

const VideoFrame: React.FC<IVideoFProps> = ({
  className = "",
  url,
  style,
  title,
  description
}) => {
  return (
    <div className={className} style={style}>
      <div
        className="absolute z-10 pt-10"
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          margin: " 0 8px",
          overflow: "hidden"
        }}
      >
        <span
          className="text-white text-sm lg:text-lg"
          style={{
            fontFamily: "Segoe UI,sans-serif",
            fontWeight: "bold",
            letterSpacing: "0",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            textShadow: "0 0 2px rgba(0,0,0,1)"
          }}
        >
          {title}
        </span>
      </div>
      <div className="w-full flex h-auto lg:h-auto mt-8 mx-auto justify-center">
        <ReactPlayer url={url}/>
      </div>
      <p className="text-center mt-6">{description}</p>
    </div>
  );
};

export default VideoFrame;
