import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import Root from "../components/layout/root";
import VideoFrame from "../components/controls/video-frame";

const Videos: React.FC = () => {
  return (
    <Root>
      <div className="container ">
        <div className="flex flex-col items-center w-full">
          <div className="flex flex-col xl:flex-row items-center">
            <VideoFrame
              className="p-5 w-3/5 xl:w-full"
              url="https://www.youtube.com/watch?v=ztW3eUlQ_3M&t=53s"
              title=""
              description=""
            />
            <div style={{ maxWidth: "60%" }}>
              <p
                className="font-bold text-highlight-600"
                style={{
                  fontSize: "32px"
                }}
              >
                Porsche 911 GT3-R 4.0 Onboard
              </p>
              <p className="font-bold text-secondary-500">
                2019 Rampa da Falperra
              </p>
              <span className="text-black font-semibold">
                Specialist José Silvino Pires attacked this year´s edition of
                the event at the wheel of this spectacular looking Porsche 911
                GT3-R MY2012. With 500Hp/9.000Rpm from its 4.0L Naturally
                Aspirated Flat 6 engine this was quite the departure from the
                last machines used by the Portuguese Racing Driver/Businessman.
                With its usual Ford Escort Cosworth and Escort Mk1 BDA being
                rebuilt he used the German Endurance Racer to achieve a very
                respectable 2nd Place on its Class as well as being among the 10
                Fastest Touring Cars present on the event. It was also one of
                the most sought after Monsters by the public because of its
                excentric livery, sporting a JPS-inspired colour scheme mixed
                with rusty effects for quite the spectacular look.
              </span>
            </div>
          </div>

          <div className="flex flex-col sm:flex-col md:flex-col xl:flex-row lg:flex-col">
            <VideoFrame
              className="self-center w-3/5 lg:w-full lg:h-full lg:p-4"
              url="https://www.facebook.com/StandCarvalho-109793546035216/videos/1409395705883311/"
              title="Good Morning and good vibes ...."
            />

            <VideoFrame
              className="self-center w-3/5 lg:w-full lg:h-full lg:p-4"
              url="https://www.facebook.com/StandCarvalho-109793546035216/videos/553567558800424"
              title="Happy Days ..."
            />
          </div>

          <div className="flex flex-col sm:flex-col md:flex-col xl:flex-row lg:flex-col">
            <VideoFrame
              className="self-center w-3/5 lg:w-full lg:h-full lg:p-4"
              url="https://www.facebook.com/StandCarvalho-109793546035216/videos/2091925190871505/"
              title="CAN-AM MAVERICK X3 X-RS 1000 TURBO - 2018"
            />

            <VideoFrame
              className="self-center w-3/5 lg:w-full lg:h-full lg:p-4"
              url="https://www.facebook.com/StandCarvalho-109793546035216/videos/593198541028045"
              title="A LITTLE VIDEO FOR PORSCHE´S FANS ..."
            />
          </div>

          <div className="flex flex-col sm:flex-col md:flex-col xl:flex-row lg:flex-col">
            <VideoFrame
              className="self-center w-3/5 lg:w-full lg:h-full lg:p-4"
              url="https://www.youtube.com/watch?v=GazMiqry5B0"
              title=""
              description="Rampa de Cerveira 2012"
            />
          </div>
        </div>
      </div>
    </Root>
  );
};

export default Videos;
